import { Box, Text } from '@tyb-u/tyb-ui-components';

const RepCardCoverPrompt = ({ name }) => {
  return (
    <Box>
      {name ? (
        <Text variant="text4-500" pb="0" data-testid="challenge-prompt-name">
          {name}
        </Text>
      ) : (
        <Box width="86%" height="30px" bg="gray02" mt="2" />
      )}
    </Box>
  );
};

export default RepCardCoverPrompt;
