import { CollectionTypes } from './constants';
import { BrandEventData } from './graphql/queries/brandEvents';
import { IBrand } from './interface/IBrand';
import { IBrandWallet } from './interface/IBrandWallet';
import { IUser } from './interface/IUser';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

export interface InteractionType {
  id: string;
  type: string;
  header: string;
  brand: {
    name: string;
  };
  reward: {
    type: string;
    quantity: number;
  };
  feedback: {
    id: string;
    questions: [
      {
        id: string;
        body: string;
        options: [
          {
            id: string;
            body: string;
          }
        ];
      }
    ];
  };
}

export enum MultipleChoiceOptionType {
  TEXT_CHOICE = 'TEXT_CHOICE',
  IMAGE_CHOICE = 'IMAGE_CHOICE',
}

export interface IMultipleChoiceOption {
  id: number;
  title: string;
  value: string;
  label?: string;
  imageUrl?: string;
  type: MultipleChoiceOptionType;
}

export type RepCardTypes = 'SURVEY' | 'PRODUCT_PURCHASE' | 'INSTANT_GRATIFICATION' | 'GALLERY' | 'SOCIAL_SHARE';

export enum RepCardTypesEnum {
  SURVEY = 'SURVEY',
  PRODUCT_PURCHASE = 'PRODUCT_PURCHASE',
  INSTANT_GRATIFICATION = 'INSTANT_GRATIFICATION',
  GALLERY = 'GALLERY',
  SOCIAL_SHARE = 'SOCIAL_SHARE',
}

export enum RepCardRewardTypes {
  COLLECTIBLE = 'COLLECTIBLE',
  BRAND_COIN = 'BRAND_COIN',
  REPS_ONLY = 'REPS_ONLY',
  CHANNEL_ACCESS = 'CHANNEl_ACCESS',
}

export type RewardTypes =
  | RepCardRewardTypes.CHANNEL_ACCESS
  | RepCardRewardTypes.REPS_ONLY
  | RepCardRewardTypes.BRAND_COIN
  | RepCardRewardTypes.COLLECTIBLE;

export type SlideTypes = 'MULTIPLE_CHOICE' | 'HOTSPOT' | 'SLIDER' | 'TEXT_RESPONSE' | 'MEDIA_UPLOAD' | 'NONE';

export type MultipartUploadStatus = 'IDLE' | 'PREPARING' | 'UPLOADING' | 'FINISHED' | 'ERROR' | 'ABORTED';

export type ParticipationForBrandEventStatus = 'PRE_RSVP' | 'PRE_CHECKED_IN' | 'RSVP' | 'CHECKED_IN';

export type IntentionParticipationForBrandEventStatus = 'RSVP' | 'CHECK_IN';

export interface RepCardPreview {
  id: number;
  uuid: string;
  prompt: string;
  type: RepCardTypes;
  timeLimit: string;
  coverImageUrl: string;
  reward: RepCardReward;
  hideCollectibleResponses?: boolean;
  participationCount?: number;
  repeatParticipations?: boolean;
  interactivity: IInteractivity;
  status: RepcardStatusTypes;
  participantUsersHighlight?: IUser[];
  participationCounts?: number;
}

export interface IReward {
  type: RewardTypes;
  assetId?: string;
  contractAddress?: string;
  quantity?: number;
  reps?: number;
}

export interface RepCardReward extends IReward {
  metadata?: RepCardRewardMetadata;
  requiresApproval?: boolean;
}

export interface RepCardRewardMetadata {
  name: string;
  description: string;
  image?: string;
  animationUrl?: string;
  additionalDetails?: string;
}

export type PeriodTypes = 'm' | 'h' | 'd' | 'w';

export type VisibilityTypes = 'PUBLIC' | 'NFT_KEY' | 'CAMPAIGN_ONLY';

export type RepcardStatusTypes = 'DRAFT' | 'PUBLIC' | 'INACTIVE' | 'ARCHIVED';

export enum RepCardStatusEnum {
  DRAFT = 'DRAFT',
  PUBLIC = 'PUBLIC',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum RepCardVisibilityEnum {
  PUBLIC = 'PUBLIC',
  NFT_KEY = 'NFT_KEY',
}

export type CampaingParticipationStatusTypes = 'IN_PROGRESS' | 'COMPLETED' | 'WAITLISTED';

export interface ISlide {
  id: number | null;
  type: SlideTypes;
  title: string;
  questionTitle?: string;
  multipleChoiceOptions: IMultipleChoiceOption[];
}

export interface ICoin {
  name: string;
  balance: string;
}

export interface ICollectibleMetadata {
  name: string;
  description: string;
  image?: string | null;
  animationUrl?: string | null;
  externalUrl?: string | null;
  additionalDetails?: string | null;
}

export interface ICollectibleExtraMetadata {
  contractAddress: string;
  tokenId: string;
  description: string;
  additionalDetails?: string | null;
}

export interface ICollectibleOwner {
  address: string;
  balance: number;
}

export interface ICollectible {
  tx?: string;
  nonce?: number;
  blockNumber?: number;
  address?: string;
  owners?: ICollectibleOwner[];
  tokenId: string;
  metadata: ICollectibleMetadata;
  extraMetadata: ICollectibleExtraMetadata;
  uri: string;
  balance: number;
  totalSupply?: number;
  maxSupply?: number;
  type?: ICollectionTypes;
  status?: string;
  contractAddress?: string;
  byEvent?: boolean;
  symbol?: string;
}

export interface IMint {
  tokenId: string;
  name: string;
  totalSupply: number;
  metadata: ICollectibleMetadata;
  uri: string;
}

export interface IDeployTransaction {
  _tmId: string;
  tx: string;
  payload: {
    name: string;
    description: string;
    image: string;
  };
}

export interface ICollectibleByAsset {
  assetID: string;
  collectibles: ICollectible[];
}
[];

export interface ICollectiblePayload {
  avalanche: {
    description: string;
    img: string;
    name: string;
    type: string;
    version: number;
  };
}

export type ICollectionTypes = keyof typeof CollectionTypes;

export interface ICollection {
  address: string;
  contractAddress: string;
  type: ICollectionTypes;
  metadata?: ICollectionMetadata;
  baseCollectibleMetadata?: ICollectibleMetadata;
  collectibleExtraMetadatas: ICollectibleExtraMetadata[];
  totalSupply?: number;
  maxSupply?: number;
  createdAt?: string;
}

export interface ICollectionMetadata {
  name: string;
  description: string;
  image: string;
}

export interface IRepCardUnlocked {
  name: string;
  image?: string | null;
  animationUrl?: string | null;
}

export type IFundingProjectStatus = 'ACTIVE' | 'DRAFT' | 'COMPLETED';

export interface IFundingStoreItem {
  amount: number;
  brandId: number;
  contractAddress: string;
  description: string;
  image: string;
  tokenId: string;
  referenceId: string;
  sku: string;
  type: string;
  quantity: number;
  expireDate: number;
  statistics: {
    featuredBuyers?: {
      email: string;
      userName: string | null;
      avatarUrl: string | null;
    }[];
    totalBuyers?: number;
    totalPurchases: number;
  };
}

export interface IAvailableFundingProject {
  brand: {
    id: number;
    logoUrl: string | null;
    name: string;
    uuid: string;
    brandWallet: IBrandWallet;
  };
  name: string;
  image: string;
  description: string;
  status: IFundingProjectStatus;
  storeItem: IFundingStoreItem | null;
  uuid: string;
  entitySlug: {
    slug: string;
    altId: string;
  };
}

export interface IFundingProject {
  brand: {
    id: number;
    uuid: string;
    name: string;
    urlSlug: string;
    logoUrl: string | null;
    brandWallet: IBrandWallet;
    description?: string;
    minParticipationAge?: number;
  };
  description: string;
  image: string;
  name: string;
  hidden: boolean;
  sections: {
    body: string;
    title: string;
    uuid: string;
  }[];
  status: IFundingProjectStatus;
  storeItem: IFundingStoreItem | null;
  entitySlug: {
    slug: string;
    altId: string;
  };
  id: number;
  uuid: string;
  createdAt: string;
}

export interface IChannelBrand {
  id: number;
  uuid: string;
  name: string;
  description: string;
  tokenId: string;
  avatarUrl: string;
  logoUrl: string;
  urlSlug: string;
}

export interface IUserChannel {
  id: number;
  name: string;
  description: string;
  tokenId: string;
  brand: IBrand;
  gate?: IBrandGate;
}

export type TokenType = 'USER_ROLE';

export interface IMultipartUpload {
  uploadId: string;
  bucketName: string;
  objectKey: string;
}

export interface INotification {
  uuid: string;
  context: 'BRAND_ADMIN' | 'CONSUMER' | 'TYB_ADMIN';
  name: string;
  message: string;
  status: 'NOTIFIED' | 'UNNOTIFIED' | 'READ';
  createdAt: Date;
  readAt: Date;
  items: INotificationItem[];
  brand?: {
    uuid: string;
    name: string;
    avatarUrl?: string;
    logoUrl?: string;
    urlSlug: string;
    brandWallet: IBrandWallet;
  };
}

export interface INotificationItem {
  info?: any;
  contractAddress?: string;
  tokenId?: string;
  type: 'COLLECTIBLE' | 'FUNDING_PROJECT' | 'REPCARD' | 'USER' | 'BRAND_EVENT' | 'CUSTOM';
  fundingProject?: Partial<IFundingProject>;
  repCard?: {
    prompt: string;
    coverImageUrl: string;
    id: number;
    reward?: {
      type: string;
      assetId: string;
      contractAddress: string;
      reps: number;
      quantity: number;
    };
    brand?: {
      id: number;
      uuid: string;
      name: string;
      logoUrl: string | null;
      brandWallet?: IBrandWallet;
    };
  };
  brandEvent?: Partial<BrandEventData>;
  user?: {
    id: number;
    avatarUrl: string;
    userName: string;
  };
}

export interface IChannelUser {
  id: string;
  name: string | null;
  username: string;
  profilePicture: string | null;
  lastActive: string;
}

export type Hi5Type = 'BRAND' | 'REPCARD_PARTICIPATION' | 'REPCARD' | 'TOPIC_COMMENT';

export interface IBrandReward {
  type: RewardTypes;
  collectible?: Partial<ICollectible>;
  quantity: number;
}

export interface IBrandGate {
  contractAddress: string;
  quantity: number;
  tokenId?: string;
}

export interface IInteractivity {
  uuid: string;
  totalHi5s: number;
  totalComments: number;
}

export type InteractivitySourceType =
  | 'USER'
  | 'BRAND'
  | 'REPCARD'
  | 'REPCARD_SLIDE'
  | 'TOPIC_COMMENT'
  | 'REPCARD_SLIDE_ANSWER'
  | 'REPCARD_PARTICIPATION';

export interface IStoreItemGift {
  uuid: string;
  gifted: boolean;
  quantity: number;
  sku: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
}

export type AuthType = 'EMAIL' | 'SMS' | 'SOCIAL';

export interface IPaginatedVars {
  skip: number;
  take: number;
}

export interface IPaginatedResponse<T> {
  pagination: {
    total: number;
    skip: number;
    take: number;
  };
  items: Array<T>;
}
export interface IRepCard {
  id: string;
  uuid: string;
  brand: {
    name: string;
    brandWallet: {
      collections: ICollection[];
    };
  };
  name: string;
  prompt: string;
  type: RepCardTypes;
  timeLimit: string;
  coverImageUrl: string | null;
  reward: {
    type: RewardTypes;
    reps: number;
    assetId?: string;
    contractAddress?: string;
    quantity: number;
  };
  gate?: IBrandGate;
  interactivity: IInteractivity;
  createdAt: string;
  visibility: string;
}

export interface IRepCardsData {
  repCards?: IRepCard[];
}

export interface IRepCardsVars {
  status: RepcardStatusTypes[];
}

export interface ISocialShareUser {
  pk: string;
  username: string;
  name?: string;
  profilePictureUrl: string;
  isPrivate: boolean;
}

export type ISocialPlatforms = 'INSTAGRAM' | 'TIK_TOK';

export interface ISocialSharePlatformApp {
  platform: ISocialPlatforms;
  appKey: string;
  appSecret: string;
  active: boolean;
}

export enum LoginMethod {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export interface RedemptionCoupon {
  code: string;
  expiredAt: string;
}

export type CoinRedemptionType = 'checkout' | 'subscription';

export type RepCardParticipationStatusType = 'COMPLETED' | 'IN_PROGRESS' | 'DISCARDED' | 'DENIED';

export type RepCardParticipationApprovalStatusFilter = 'APPROVED' | 'DENIED' | 'NONE';
export type RepCardParticipationApprovalStatus = 'APPROVED' | 'DENIED';

export interface IInternalForwardRequestData {
  ref: string;
  from: string;
  to: string;
  value: number;
  gas: string;
  data: string;
}

export interface IRepcardConsolidatedParticipation {
  status: 'ONGOING' | 'COMPLETED';
  hasBeenApproved: boolean;
  hasBeenRejected: boolean;
  total: number;
  totalPending: number;
  totalRewarded: number;
}
