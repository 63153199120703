import { useLazyQuery, useQuery } from '@apollo/client';
import mParticle from '@mparticle/web-sdk';
import { Box, Text } from '@tyb-u/tyb-ui-components';
import React, { useEffect, useState } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { GET_BRAND_MEMBERS, IBrandMembersData, IBrandMembersVars } from '../../graphql/queries/getBrandMembers';
import UserInfiniteListModal from '../../modals/UserInfiniteListModal/UserInfiniteListModal';
import { AvatarRow } from '../AvatarRow/AvatarRow';

interface TeamMembersPreviewProps {
  brandUuid: string;
  brandName: string;
  teamMembers: {
    id: string;
    avatar: string | null;
  }[];
  max: number;
  total: number;
  itemWidth?: number;
  showTitle?: boolean;
  staticVersion?: boolean;
}

const TeamMembersPreview: React.FC<TeamMembersPreviewProps> = ({
  brandUuid,
  brandName,
  teamMembers,
  max,
  total,
  itemWidth = 64,
  showTitle = true,
  staticVersion,
}) => {
  const [showMembersList, setShowMembersList] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>(null);

  const [refetchBrandMembers, { data: brandMembersData }] = useLazyQuery<IBrandMembersData, IBrandMembersVars>(
    GET_BRAND_MEMBERS,
    {
      variables: {
        uuid: brandUuid,
        skip: 0,
        take: 20,
      },
    }
  );

  const moreMembers = async (take: number, skip: number) => {
    refetchBrandMembers({
      variables: {
        uuid: brandUuid,
        skip,
        take,
      },
    });
  };

  useEffect(() => {
    if (showMembersList) {
      refetchBrandMembers();
    }
  }, [showMembersList]);

  useEffect(() => {
    if (showMembersList && brandName && brandUuid) {
      logMparticleEvent('view_team_members', mParticle.EventType.Other, {
        brand_uuid: brandUuid,
        brand_name: brandName,
      });
    }
  }, [brandName, brandUuid, showMembersList]);

  if (!total) {
    total = teamMembers.length;
  }

  return (
    <Box data-testid="team-members-preview">
      {showTitle && teamMembers.length > 0 && (
        <Text mb="2" variant="text4-600">
          Team Members
        </Text>
      )}
      {showMembersList && (
        <UserInfiniteListModal
          isOpen={showMembersList}
          users={brandMembersData?.brand?.brandMembers?.map((item) => item.user) || []}
          onViewWallet={setSelectedUserId}
          title={`TEAM MEMBERS`}
          loadMore={moreMembers}
          total={brandMembersData?.brand?.brandMembersCount || 0}
          onClose={() => {
            setShowMembersList(false);
            moreMembers(20, 0);
          }}
        />
      )}
      <AvatarRow
        max={max}
        showUserId={showMembersList ? selectedUserId : null}
        itemWidth={itemWidth}
        total={total}
        showList={() => setShowMembersList(true)}
        list={teamMembers.map((item) => ({ id: item.id, avatarUrl: item.avatar }))}
        onClose={() => setSelectedUserId(null)}
        staticVersion={staticVersion}
      />
    </Box>
  );
};

export default TeamMembersPreview;
