import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useEffect, useState } from 'react';

import { RepCardRewardTypes } from '../../../types';
import ImageCDN from '../../ImageCDN';
import VideoCDN from '../../VideoCDN';

interface RepCardFooter {
  image?: string | null;
  animationUrl?: string | null;
  text: string;
  subtitle?: string;
  subtitle2?: string;
}

const RepCardRewardPreview = ({ rewardType, rewardMetadata, rewardQuantity = 1 }) => {
  const [description, setDescription] = useState<RepCardFooter>(null);

  useEffect(() => {
    if (rewardType === 'REPS_ONLY') {
      setDescription({
        text: 'Earn +1 rep',
      });
    } else if (rewardMetadata) {
      if (rewardType === RepCardRewardTypes.COLLECTIBLE) {
        setDescription({
          image: rewardMetadata?.image,
          animationUrl: rewardMetadata?.animationUrl,
          text: `Unlocks: ${rewardMetadata?.name ?? 'An NFT collectible'}`,
          subtitle2: `${rewardMetadata?.description}`,
        });
      } else if (rewardType === RepCardRewardTypes.BRAND_COIN) {
        setDescription({
          image: rewardMetadata?.image,
          text: `Earn: ${rewardQuantity}x ${rewardMetadata?.name} ${rewardQuantity > 1 ? 'coins' : 'coin'}`,
        });
      }
    } else {
      setDescription({
        text: '',
        subtitle: '',
        subtitle2: '',
      });
    }
  }, [rewardType, rewardMetadata, rewardQuantity, setDescription]);

  if (!description)
    return (
      <Box>
        <Box width="100%" height="18px" bg="gray02" mb="2" mt="2" />
        <Box width="32%" height="18px" bg="gray02" />
      </Box>
    );

  const imageSize = '38px';

  return (
    <Flex alignItems="center" mt="2" style={{ marginTop: '10px' }}>
      {description.animationUrl ? (
        <Box height={imageSize} width={imageSize} mr="2" borderRadius="100%" overflow="hidden">
          <VideoCDN src={description.animationUrl} preload="metadata" autoPlay={true} height="100%" />
        </Box>
      ) : description.image ? (
        <ImageCDN
          resize="sm"
          src={description.image}
          height={imageSize}
          width={imageSize}
          maxWidth={imageSize}
          mr="2"
          borderRadius="100%"
          style={{ objectFit: 'cover' }}
        />
      ) : null}
      <Box style={{ width: `calc(100% - ${imageSize})` }}>
        <Text variant="text5-400" p="0">
          {description.text}
        </Text>
        {description.subtitle && (
          <Text variant="text5-400" color="gray04" p="0">
            {description.subtitle}
          </Text>
        )}
        {description.subtitle2 && (
          <Text variant="text6-400" color="gray04" p="0">
            {description.subtitle2}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default RepCardRewardPreview;
