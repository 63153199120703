import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useMemo } from 'react';

import CollectibleMedia from '../../../components/CollectibleMedia';

const GalleryReward = ({
  rewardType,
  rewardQuantity,
  rewardMetadata,
  showMedia = true,
  earned = false,
  small = false,
  containerStyle = {},
  showExtraRewardText = true,
  truncateText = false,
}) => {
  const getRewardText = useCallback(() => {
    switch (rewardType) {
      case 'COLLECTIBLE':
        if (showExtraRewardText) {
          return `Earn${earned ? 'ed' : ''} ${rewardMetadata?.name} collectible`;
        }
        return rewardMetadata?.name;
      case 'BRAND_COIN':
        if (showExtraRewardText) {
          return `Earn${earned ? 'ed' : ''} ${rewardQuantity} ${rewardMetadata?.name} ${
            rewardQuantity > 1 ? 'coins' : 'coin'
          }`;
        }
        return `${rewardQuantity}x coins`;
      default:
        if (showExtraRewardText) {
          return `Earn${earned ? 'ed' : ''} +1 Rep`;
        }
        return '+1 Rep';
    }
  }, [rewardType, rewardQuantity, rewardMetadata, showExtraRewardText]);

  let textStyle: React.CSSProperties = useMemo(() => {
    return truncateText
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '80%',
        }
      : {};
  }, [truncateText]);

  textStyle =
    !rewardMetadata && (rewardType === 'COLLECTIBLE' || rewardType === 'BRAND_COIN')
      ? { ...textStyle, filter: 'blur(5px)' }
      : textStyle;

  return (
    <Flex
      data-testid="challenge-reward"
      flex={1}
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="center"
      __css={{
        gap: '5px',
      }}
      style={{ ...containerStyle }}
    >
      {rewardType !== 'REPS_ONLY' && showMedia && (
        <Box width={small ? '16px' : '32px'} height={small ? '16px' : '32px'}>
          <CollectibleMedia metadata={rewardMetadata} width="100%" height="100%" size={small ? 'sm' : 'md'} />
        </Box>
      )}

      <Text
        style={{
          ...textStyle,
        }}
        variant="text6-400"
      >
        {getRewardText()}
      </Text>
    </Flex>
  );
};

export default GalleryReward;
