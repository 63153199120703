import { gql } from '@apollo/client';

export interface IBrandMembersVars {
  uuid: string;
  skip: number;
  take: number;
}

export interface IBrandMembersData {
  brand: {
    brandMembersCount: number;
    brandMembers: {
      createdAt: Date;
      user: {
        id: number;
        email: string;
        phoneNumber: string;
        userName: string;
        avatarUrl: string;
        urlSlug: string;
        cChainPublicAddress: string;
        activatedAt: Date;
      };
    }[];
  };
}

export const GET_BRAND_MEMBERS = gql`
  query BrandMembers($uuid: String!, $skip: Int, $take: Int) {
    brand(uuid: $uuid) {
      name
      brandMembersCount: memberCount
      brandMembers(skip: $skip, take: $take) {
        createdAt
        user {
          id
          email
          phoneNumber
          userName
          avatarUrl
          cChainPublicAddress
        }
      }
    }
  }
`;
