import mParticle from '@mparticle/web-sdk';
import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { IUser } from 'src/interface/IUser';
import { logMparticleEvent } from 'src/utils/mparticle';

import GalleryReward from '../../modals/GalleryViewModal/components/GalleryReward';
import { RepCardCompleteModalCloseStatus } from '../../modals/RepCardCompleteModal/RepCardCompleteModal';
import { IInteractivity, RepCardRewardMetadata, RepCardTypes, RewardTypes } from '../../types';
import { extractTimeLimit, timePeriodString } from '../../utils';
import ImageCDN from '../ImageCDN';
import TeamMembersPreview from '../TeamMembersPreview';
import VideoCDN from '../VideoCDN';
import RepCardCoverPrompt from './Components/RepCardCoverPrompt';
import RepCardRewardPreview from './Components/RepCardRewardPreview';

const RepCardCompleteModal = React.lazy(() => import('../../modals/RepCardCompleteModal/RepCardCompleteModal'));

interface RepCardPropsData {
  id: string | null;
  uuid: string;
  brandUuid?: string | null;
  brandLogoUrl?: string | null;
  brandName?: string | null;
  name: string;
  hideCollectibleResponses?: boolean;
  coverImageUrl?: string;
  rewardType?: RewardTypes;
  rewardMetadata?: RepCardRewardMetadata;
  rewardTokenId?: string;
  rewardQuantity?: number;
  timeLimit?: string | null;
  hasCompleted?: boolean;
  interactivity: IInteractivity;
  type?: RepCardTypes;
  repeatParticipations?: boolean;
  participants?: IUser[];
  participationCounts?: number;
  maxParticipations?: number;
}

interface RepCardProps {
  isSimulation?: boolean;
  data: RepCardPropsData;
  hasButton?: boolean;
  disabledButton?: boolean;
  buttonText?: string;
  onPressButton?: () => void;
  onPressButtonOnFinish?: () => void;
  onDiscardRepcard?: (repcardId: string) => void;
  onClose: (status: RepCardCompleteModalCloseStatus, hasNewCompletedParticipation?: boolean) => void;
  onOpen?: (repcardId: string) => Promise<void> | void;
  onlyPreview?: boolean;
  elevation?: 'none' | 'high' | 'low';
  border?: 'none' | 'thin';
  showHide?: boolean;
  width?: string;
  minWidth?: string;
  openCompleteModal?: boolean;
  hide?: boolean;
}

const formatTimeLimit = (limit) => {
  const [time, period] = extractTimeLimit(limit);
  return `${timePeriodString(parseInt(time.toFixed(0), 10), period)} left`.toUpperCase();
};

const RepCard = React.forwardRef<HTMLDivElement, RepCardProps>(
  (
    {
      data,
      isSimulation = false,
      hasButton = false,
      disabledButton = false,
      buttonText = 'Vote now',
      onPressButton = () => console.log('RepCard PressButton'),
      onlyPreview = false,
      elevation = 'none',
      border = 'thin',
      onPressButtonOnFinish = null,
      onClose = () => console.log('RepCard OnClose'),
      onOpen = () => null,
      showHide = true,
      width = '320px',
      minWidth = '320px',
      openCompleteModal = false,
      hide = false,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [previewCoverType, setPreviewCoverType] = useState<string | null>(null);
    const isExpired = data.timeLimit && dayjs().isAfter(dayjs(data.timeLimit));

    const getButtonText = (data: RepCardPropsData) => {
      const { hasCompleted, type, maxParticipations, participationCounts } = data;
      const hasReachedMaxParticipations = maxParticipations !== null && participationCounts >= maxParticipations;

      if (hasCompleted) {
        const isGallery = type === 'GALLERY';
        const isSurvey = type === 'SURVEY';

        if (isGallery) return 'View gallery';
        if (isSurvey) return 'View responses';

        return 'Reward claimed';
      } else if (hasReachedMaxParticipations) {
        return 'Challenge closed';
      } else {
        const isInstantGratification = type === 'INSTANT_GRATIFICATION';

        return isInstantGratification ? 'Get reward' : 'View challenge';
      }
    };

    useMemo(() => {
      setIsOpen(openCompleteModal);
    }, [openCompleteModal]);

    useMemo(() => {
      if (onlyPreview) {
        if (data.coverImageUrl && data.coverImageUrl.length > 0 && !data.coverImageUrl.startsWith('https://')) {
          fetch(data.coverImageUrl).then(async (result) => {
            const blob = await result.blob();
            setPreviewCoverType(blob.type.split('/')[1]);
          });
        } else {
          setPreviewCoverType(undefined);
        }
      }
    }, [data.coverImageUrl]);

    const renderCover = (width = '320px') => {
      if (data.coverImageUrl) {
        const fileFormat = previewCoverType
          ? previewCoverType
          : data.coverImageUrl.slice(data.coverImageUrl.lastIndexOf('.') + 1);

        if (fileFormat.toLowerCase() === 'mp4') {
          return (
            <VideoCDN
              height="100%"
              muted
              autoPlay
              controls={false}
              loop
              width="100%"
              playsInline
              preload="metadata"
              src={data.coverImageUrl}
              style={{ objectFit: 'cover' }}
              data-testid="challenge-cover-video"
            />
          );
        }

        return (
          <ImageCDN
            src={data.coverImageUrl}
            width="100%"
            borderRadius="none"
            style={{ objectFit: 'cover' }}
            height={width}
            data-testid="challenge-cover-img"
            resizeTo="lg"
          />
        );
      }

      if (data.hasCompleted) {
        return <Box bg="transparent" width="100%" height={!onlyPreview && showHide ? '36px' : '10px'} />;
      }

      <Box bg="transparent" width="100%" height={!onlyPreview && showHide ? '25px' : '10px'} />;
    };

    const canMparticle = () => data.brandName && data.brandUuid && !isSimulation && !onlyPreview;

    const canInteract = () => {
      const isValidType = ['GALLERY', 'SURVEY', 'PRODUCT_PURCHASE', 'SOCIAL_SHARE'].includes(data.type);
      const isNotCompletedInstantGratification = data.type === 'INSTANT_GRATIFICATION' && !data.hasCompleted;
      const hasReachedMaxParticipations =
        data.maxParticipations !== null && data.participationCounts >= data.maxParticipations;
      const showGallery = ['GALLERY', 'SURVEY'].includes(data.type) && data.hasCompleted;
      return (
        !onlyPreview &&
        (showGallery || !isExpired) &&
        (showGallery || !hasReachedMaxParticipations) &&
        (isValidType || isNotCompletedInstantGratification)
      );
    };

    const handleClick = () => {
      if (canInteract()) {
        if (canMparticle()) {
          logMparticleEvent('view_repcard', mParticle.EventType.Other, {
            brand_uuid: data.brandUuid,
            brand_name: data.brandName.toLowerCase(),
            repcard_prompt: data.name,
            repcard_type: data.type?.toLowerCase(),
            repcard_id: data.id,
          });
        }
        setIsOpen(true);
        onOpen(data.id.toString());
      }
    };

    return (
      <>
        {!onlyPreview && data.id && (
          <React.Suspense fallback={null}>
            <RepCardCompleteModal
              id={data.id}
              brandUuid={data.brandUuid}
              rewardType={data.rewardType}
              rewardMetadata={data.rewardMetadata}
              rewardTokenId={data.rewardTokenId}
              isSimulation={isSimulation}
              isOpen={isOpen}
              onClose={(status, hasNewCompletedParticipation) => {
                setIsOpen(false);
                if (canMparticle()) {
                  let eventName = '';

                  if (hasNewCompletedParticipation) {
                    eventName = 'complete_repcard';
                  } else if (status == 'DISMISS') {
                    eventName = 'dismiss_repcard';
                  } else if (status == 'AGE_RESTRICTION_DISMISS') {
                    eventName = 'dismiss_age_restriction_repcard';
                  }

                  if (eventName) {
                    logMparticleEvent(eventName, mParticle.EventType.Other, {
                      brand_uuid: data.brandUuid,
                      brand_name: data.brandName.toLowerCase(),
                      repcard_prompt: data.name,
                      repcard_type: data.type?.toLowerCase(),
                      repcard_id: data.id,
                    });
                  }
                }
                onClose(status, hasNewCompletedParticipation);
              }}
              showButtonOnFinish={hasButton}
              onPressButtonOnFinish={onPressButtonOnFinish}
              hasCompleted={data.hasCompleted}
              repeatParticipations={data.repeatParticipations}
            />
          </React.Suspense>
        )}
        <Box
          ref={ref}
          elevation={elevation}
          border={border}
          borderRadius="md"
          borderColor="neutrals-100"
          overflow="hidden"
          __css={onlyPreview ? {} : { cursor: 'pointer' }}
          bg="white"
          position={'relative'}
          width={width}
          minWidth={minWidth}
          display={hide ? 'none' : undefined}
          onClick={handleClick}
          height={data.coverImageUrl && data.type !== 'SOCIAL_SHARE' ? '100%' : undefined}
        >
          <Flex height="100%">
            {data.type === 'SOCIAL_SHARE' && (
              <Box minHeight="110px">
                <Flex flexDirection="row" px="3" style={{ marginBottom: '10px', marginTop: '10px' }}>
                  {data.coverImageUrl && (
                    <Box height={96} width={96} mr="3" borderRadius="md" overflow="hidden">
                      {renderCover('96px')}
                    </Box>
                  )}
                  <Flex flexDirection="column" flex={1}>
                    <RepCardCoverPrompt name={data.name} />
                    <Box pt="2">
                      <GalleryReward
                        rewardQuantity={data.rewardQuantity}
                        rewardType={data.rewardType}
                        rewardMetadata={data.rewardMetadata}
                        small
                        containerStyle={{ justifyContent: 'flex-start' }}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            )}
            {data.type !== 'SOCIAL_SHARE' && (
              <Flex flexDirection="column" flex={1}>
                <Flex flexDirection="row" px="3">
                  {data.brandLogoUrl && (
                    <>
                      <Flex py="6px" mr="10px" flexDirection="column" height="50px" justifyContent="center">
                        <ImageCDN src={data.brandLogoUrl} height="100%" style={{ objectFit: 'cover' }} />
                      </Flex>
                    </>
                  )}
                </Flex>
                <Box position="relative">
                  {renderCover()}

                  {!data.coverImageUrl && onlyPreview && <Box bg="gray02" width="100%" height={width} />}
                  {data.timeLimit && !data.hasCompleted && (
                    <Flex
                      justifyContent="space-between"
                      position="absolute"
                      px="2"
                      right="3"
                      bottom={data.coverImageUrl ? '3' : '-15px'}
                      mb={data.coverImageUrl ? 'unset' : '-15px'}
                      bg="white"
                      borderRadius="16px"
                      elevation="low"
                    >
                      <Text variant="text6-400" fontSize="12px" color="orange">
                        {isExpired ? 'Expired' : formatTimeLimit(data.timeLimit)}
                      </Text>
                    </Flex>
                  )}
                </Box>
                <Flex flexDirection="column" flex={1} px="3" style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <RepCardCoverPrompt name={data.name} />

                  {!['GALLERY', 'SURVEY', 'INSTANT_GRATIFICATION'].includes(data.type) && (
                    <RepCardRewardPreview
                      rewardType={data.rewardType}
                      rewardMetadata={data.rewardMetadata}
                      rewardQuantity={data.rewardQuantity}
                    />
                  )}

                  {['GALLERY', 'SURVEY', 'INSTANT_GRATIFICATION'].includes(data.type) && (
                    <Flex flexDirection="column" mt="2" flex="1">
                      <Flex flex={1} alignItems="flex-end">
                        <Flex flex={1}>
                          <Flex flex={1} mr="3" my="1" alignItems="center">
                            <Box>
                              {data.participants && (
                                <TeamMembersPreview
                                  brandUuid={data.brandUuid}
                                  brandName={data.brandName}
                                  teamMembers={data.participants.map((item) => ({
                                    id: item.id.toString(),
                                    avatar: item.avatarUrl,
                                  }))}
                                  max={5}
                                  total={data.participationCounts}
                                  itemWidth={36}
                                  showTitle={false}
                                  staticVersion={true}
                                />
                              )}
                            </Box>
                          </Flex>
                          <Flex alignItems="center" my="2" data-testid="challenge-reward-section">
                            <GalleryReward
                              rewardQuantity={data.rewardQuantity}
                              rewardType={data.rewardType}
                              rewardMetadata={data.rewardMetadata}
                              small
                            />
                          </Flex>
                        </Flex>
                      </Flex>

                      <Button disabled={!canInteract()} mt="2">
                        <Text color="white" variant="text5-400">
                          {getButtonText(data)}
                        </Text>
                      </Button>
                    </Flex>
                  )}
                </Flex>
                {hasButton && (
                  <Flex px="3" pb="2" flexDirection="column">
                    <Button disabled={disabledButton} onClick={() => onPressButton()} mt="2" scale="sm">
                      {buttonText}
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Box>
      </>
    );
  }
);

export default RepCard;
